import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { HelperText, Button, Card, Title, Paragraph, List, Portal, Dialog, DataTable, RadioButton, Checkbox, Drawer, TextInput } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { WebView } from 'react-native-webview';
import Recaptcha from 'react-grecaptcha';

import { emailValidator, passwordValidator } from '../utils/index';


const sitkey  = '6LdOOsQaAAAAAB7gKb_O8jA84C5FiqdVou8NSHP1';
const url = 'm1.monicatool.cloud';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    myOwnProperty: true,
    colors: {
      ...DefaultTheme.colors,
      background: '#292929',
      primary: 'white',
      accent: 'white',
      text: '#ffffff',
      surface:'#292929'
    },
  };

function SignInScreen({ navigation, route }) {

    const [email,setEmail] = useState({ value: '', error: '' })
    const [password, setPassword] = useState({ value: '', error: '' });

    const [firstOpen,setFirstOpen] = useState()
    const [link, setLink]=useState('')

    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('@id_usertable')
          return value
        }
        catch(e) {
          // error reading value
        }
      }


    const generateLink = async (text) =>{
        await fetch('https://monicatool.cloud:3001/api/generateLink',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mx: 'm1'
            })
        })
        .then((response) => response.json())
        .then((resdata) => {
            setLink(resdata)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const onLoginPressed = async () => {
        const emailError = emailValidator(email.value);
        const passwordError = passwordValidator(password.value);
    
        if (emailError || passwordError) {
            var tempEmail = email
            email.error =emailError
            setEmail({...email, tempEmail})
            password.error = passwordError
            setPassword({...password, passwordError})
            return
        }
    
        await fetch('https://monicatool.cloud:3001/api/auth/signin', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email.value,
                password: password.value
            })
        })
        .then((response) => response.json())
        .then((resdata) => {
            if (resdata.error){
                email.error = "User not found"
                setEmail({...email, tempEmail})
            }
            else{
                if(!resdata.accessToken){
                    if(resdata.message === "Email not validate"){
                        email.error = "Email not validate"
                        setEmail({...email, tempEmail})
                    }
                    if(resdata.message === "Password not valid"){
                        password.error = "Password not valid"
                        setPassword({...password, passwordError})
                    }
                }
                else{
                    AsyncStorage.setItem('@token', resdata.accessToken)
                    window.location.reload()
                }
            }
        })
        .catch((error) => {
            console.log(error)
        })
    
        
    };

    useEffect(()=>{

      }, [firstOpen])

    return (
        <PaperProvider theme={theme}>
            <Portal>
                <Dialog visible={true} style={{width:'70%', alignSelf:'center'}}>
                    <Dialog.Title>
                        <View style={{flexDirection:'column', width:'100%', alignItems:'center'}}>
                            <View>
                                <Text style={{fontSize:20}}>Perform yourself - M1</Text>
                            </View>
                            <View>
                                <Text style={{fontSize:14}}>Read carefully the paper M1 and and try to instantiate it through the MONICA tool</Text>
                            </View>
                        </View>
                    </Dialog.Title>
                    <Dialog.Content>
                        <View style={{flexDirection:'column'}}>
                            <View style={styles.rowDialog}>
                                <Text style={styles.textTitle}>Welcome</Text>
                                <Text style={styles.textSubTitle}>Open the links to start</Text>
                            </View>
                            <View style={{flex: 1, alignItems:'center'}}>
                                <Recaptcha
                                    sitekey={sitkey}
                                    callback={(text)=>{generateLink(text)}}
                                    // Other props will be passed into the component.
                                    data-theme="dark"
                                />
                            </View>

                            <View style={styles.rowDialog}>
                            {link == '' ? (
                                <>
                                <TextInput
                                    label=''
                                    returnKeyType="done"
                                    value='PLEASE SOLVE CAPTCHA TO OBTAIN LINK'
                                    disabled={true}
                                    //onChangeText={text => setPassword({ value: text, error: '' })}
                                    //error={!!password.error}
                                    //errorText={password.error}
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                />
                                <TextInput
                                    label=''
                                    returnKeyType="done"
                                    value=''
                                    disabled={true}
                                    //onChangeText={text => setPassword({ value: text, error: '' })}
                                    //error={!!password.error}
                                    //errorText={password.error}
                                    underlineColor="grey"
                                    theme={{ colors: { placeholder: 'grey' } }}
                                />
                                </>
                            ) : (
                                <>
                                <View style={{flexDirection:'row', justifyContent:'space-around'}}>
                                    <View style={{width:"80%"}}>
                                        <TextInput
                                            returnKeyType="done"
                                            value={link.address1}
                                            disabled={true}
                                            //onChangeText={text => setPassword({ value: text, error: '' })}
                                            //error={!!password.error}
                                            //errorText={password.error}
                                            underlineColor="grey"
                                            theme={{ colors: { placeholder: 'grey' } }}
                                        />
                                    </View>
                                    <View style={{width:"20%"}}>
                                        <Button 
                                            style={{padding: 10}}
                                            mode="contained" 
                                            color="white"
                                            onPress={() =>{window.open(link.address1,'_blank')}}
                                        >
                                            Open
                                        </Button>
                                    </View>
                                </View>
                                <View style={{flexDirection:'row', justifyContent:'space-around'}}>
                                    <View style={{width:"80%"}}>
                                        <TextInput
                                            returnKeyType="done"
                                            value={link.address2}
                                            disabled={true}
                                            //onChangeText={text => setPassword({ value: text, error: '' })}
                                            //error={!!password.error}
                                            //errorText={password.error}
                                            underlineColor="grey"
                                            theme={{ colors: { placeholder: 'grey' } }}
                                        />
                                    </View>
                                    <View style={{width:"20%"}}>
                                        <Button 
                                            style={{padding: 10}}
                                            mode="contained" 
                                            color="white"
                                            onPress={() =>{window.open(link.address2,'_blank')}}
                                        >
                                            Open
                                        </Button>
                                    </View>
                                </View>
                                </>
                            )}
                            </View>

                                {/* <TouchableOpacity onPress={() => navigation.navigate('SignUp')}>
                                    <Text style={styles.signup}>Don’t have an account? Sign up</Text>
                                </TouchableOpacity> */}
                        </View>
                    </Dialog.Content>
                </Dialog>
            </Portal>
            <View style={{flex:1, with: useWindowDimensions().width, height:useWindowDimensions().height, backgroundColor: theme.colors.background}}>
            
            </View>
        </PaperProvider>
    );
}

export default SignInScreen

const styles = StyleSheet.create({
    title: {
      fontSize: 16,
      textTransform: 'uppercase',
    },
    textTitle: {
        color:"white",
        fontSize: 20,
        //textTransform: 'uppercase',
    },
    textSubTitle:{
        color:"white",
        fontSize: 14,
    },
    boxParameters :{
        padding: 2
    },
    rowDialog:{
        flex:1,
        paddingVertical:20
    },
    forgot:{
        textAlign: 'right',
        color:'grey',
        textDecorationLine: 'underline'
    },
    signup:{
        textAlign: 'center',
        color:'grey',
        textDecorationLine: 'underline'
    },

  });
