import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, Alert, useWindowDimensions} from 'react-native';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { Avatar, Button, Card, Title, Paragraph, List, Portal, Dialog, DataTable, RadioButton, Checkbox, Drawer, TextInput, ActivityIndicator } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from 'react-native-vector-icons/MaterialIcons';
import moment from 'moment';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    myOwnProperty: true,
    colors: {
      ...DefaultTheme.colors,
      background: '#292929',
      primary: 'white',
      accent: 'white',
      text: '#ffffff',
      surface:'#292929'
    },
};



function InsertScreen({ navigation, route }) {

    const [firstOpen,setFirstOpen] = useState()
    const [table,setTable] = useState([])
    const [tkn,setTkn] = useState()

    const getToken = async () => {
        try {
          const value = await AsyncStorage.getItem('@token')
          if(value !== null) {
            return value
          }
          else{
            console.log('error token')
            window.location.reload()
          }
        }
        catch(e) {
          // error reading value
        }
    }

    const cancel = async (id) =>{
        fetch('https://monicatool.cloud:3001/api/delTable/'+id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+tkn
            },
        })
        .then((response) => response.json())
        .then((resdata) => {
            console.log(resdata)
            if (resdata.stat === 'ok')
                window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const copy = async (id) =>{
        fetch('https://monicatool.cloud:3001/api/copyTable/'+id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+tkn
            },
        })
        .then((response) => response.json())
        .then((resdata) => {
            AsyncStorage.setItem('@id_usertable', resdata._id)
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
    }


    const newInstance = async () =>{
        fetch('https://monicatool.cloud:3001/api/addTable', {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': "Bearer "+tkn
          },
        })
        .then((response) => response.json())
        .then((resdata) => {
            AsyncStorage.setItem('@id_usertable', resdata._id)
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
    }
    
    const select = async (id) =>{
        console.log(id)
        await AsyncStorage.setItem('@id_usertable', id)
        window.location.reload()
    }

    const getTable = async (token) =>{
        fetch('https://monicatool.cloud:3001/api/getTable', {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': "Bearer "+token
          },
        })
        .then((response) => response.json())
        .then((resdata) => {
            setTable(resdata)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(()=>{
        getToken().then((token)=>{
            setTkn(token)
            getTable(token)
            
        })
    }, [firstOpen])
    return (
        <PaperProvider theme={theme}>
            <View style={{flex:1, backgroundColor: theme.colors.background}}>
                <DataTable>
                    <DataTable.Header>
                    <DataTable.Title style={{flex:4}}>Name</DataTable.Title>
                    <DataTable.Title style={{flex:2}}>Date</DataTable.Title>
                    <DataTable.Title style={{flex:1}}>Select</DataTable.Title>
                    <DataTable.Title style={{flex:1}}>Delete</DataTable.Title>
                    <DataTable.Title style={{flex:1}}>Copy</DataTable.Title>
                    </DataTable.Header>

                    {table.map((o)=>{
                        return(
                        <>
                        <DataTable.Row>
                            <DataTable.Cell style={{flex:4}} onPress={()=>select(o._id)}>{o.name}</DataTable.Cell>
                            <DataTable.Cell style={{flex:2}} onPress={()=>select(o._id)}>{moment(o.date).format('MM/DD/YY, HH:mm:ss')}</DataTable.Cell>
                            <DataTable.Cell style={{flex:1}} onPress={()=>select(o._id)}> <Icon size={20} name="done" /> </DataTable.Cell>
                            <DataTable.Cell style={{flex:1}} onPress={()=>{cancel(o._id)}}><Icon size={20} name="delete" /> </DataTable.Cell>
                            <DataTable.Cell style={{flex:1}} onPress={()=>{copy(o._id)}}><Icon size={20} name="content-copy" /> </DataTable.Cell>
                        </DataTable.Row>    
                        </>
                        )
                    })}
                    </DataTable>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{flex:1}}></View>
                        <View style={{flex:6}}>
                        <Button 
                            mode="contained" 
                            color="white"
                            onPress={()=>newInstance()}
                        >
                            New Find
                        </Button>
                        </View>
                        <View style={{flex:1}}></View>
                    </View>
            </View>
        </PaperProvider>
    );
  }


export default InsertScreen