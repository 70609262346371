import React, { useState, useEffect } from 'react'
import { View, Text, Button, useWindowDimensions } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
//import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem} from '@react-navigation/drawer'
import AsyncStorage from '@react-native-async-storage/async-storage'

import HomeScreen from './screens/cluster'
import SignInScreen from './screens/signin'
import SignUpScreen from './screens/signup'
import ForgotPasswordScreen from './screens/fgpass'
import QueryScreen from './screens/query'
import InsertScreen from './screens/insert'

function Feed({ navigation }) {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Feed Screen</Text>
      <Button title="Open drawer" onPress={() => navigation.openDrawer()} />
      <Button title="Toggle drawer" onPress={() => navigation.toggleDrawer()} />
    </View>
  );
}

function CustomDrawerContent(props) {
  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      <DrawerItem
        label="Logout"
        onPress={() => close()}
      />
    </DrawerContentScrollView>
  );
}

//const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();


function SignIn({ navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SignIn"
        component={SignInScreen}
        options={{
          title:"MONICA tool - SignIn",
          // headerLeft: () => (
          //   <Button title="menu" onPress={() => navigation.openDrawer()} />
          // ),
          headerStyle: {
            backgroundColor: '#292929'
          },
          headerTintColor: '#fff',
        }}
      />
    </Stack.Navigator>
  )
}

function SignUp({ navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SignUp"
        component={SignUpScreen}
        options={{
          title:"MONICA tool - SignUp",
          // headerLeft: () => (
          //   <Button title="menu" onPress={() => navigation.openDrawer()} />
          // ),
          headerStyle: {
            backgroundColor: '#292929'
          },
          headerTintColor: '#fff',
        }}
      />
    </Stack.Navigator>
  )
}
function ForgotPassword({ navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPasswordScreen}
        options={{
          title:"MONICA tool - ForgotPassword",
          // headerLeft: () => (
          //   <Button title="menu" onPress={() => navigation.openDrawer()} />
          // ),
          headerStyle: {
            backgroundColor: '#292929'
          },
          headerTintColor: '#fff',
        }}
      />
    </Stack.Navigator>
  )
}

function Root({route, navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title:"MONICA tool",
          // headerRight: () => (
          //   <Button title="menu" onPress={() => navigation.openDrawer()} />
          // ),
          headerLeft: () => (
            <Button title="menu" onPress={() => navigation.openDrawer()} />
          ),
          headerStyle: {
            backgroundColor: '#292929', 
          },
          headerTitleStyle: { margin: 20 },
          //headerRightContainerStyle: { margin: 20 },
          headerLeftContainerStyle: { margin: 20 },
          headerTintColor: '#fff',
        }}
      />
    </Stack.Navigator>
  );
}

function App() {

  const linking = {
    prefixes: ['https://monicatool.cloud'],
    config:{
      screens: {
        //Home: '',
        SignIn: 'signin',
        SignUp: 'signup',
        //myQuery: 'myQuery'
        Insert:'insert/:name'
      }
    }
  }

  const [firstOpen,setFirstOpen] = useState()
  const [idUserTable,setIdUserTable] = useState()
  const [state, setState]= useState(true)
  //const [token, setToken] =useState('')


  const close = async () =>{
    const value = await AsyncStorage.removeItem('@token')
    window.location.reload()
    //props.navigation.closeDrawer()
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@id_usertable')
      if(value !== null) {
        setIdUserTable(value)
      }
    }
    catch(e) {
      // error reading value
    }
  }

  const getToken = async () => {
    try {
      const value = await AsyncStorage.getItem('@token')
      if(value !== null) {
        setState(true)
        return value
      }
      else{
        setState(false)
      }
    }
    catch(e) {
      // error reading value
    }
  }

  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem('@id_usertable', value)
    } catch (e) {
      // saving error
    }
  }

  // useEffect(()=>{
  //   getToken().then((token)=>{
  //     getData()
  //     if(idUserTable){
  //       console.log('id esistente')
  //     }
  //     else{
  //       fetch('https://monicatool.cloud:3001/api/newUserTableM1', {
  //         method: 'GET',
  //         headers: {
  //             Accept: 'application/json',
  //             'Content-Type': 'application/json',
  //             'Authorization': "Bearer "+token
  //         },
  //       })
  //       .then((response) => response.json())
  //       .then((resdata) => {
  //         storeData(resdata._id)
  //         setIdUserTable(resdata._id)
  //       })
  //       .catch((e) => {
  //         console.log(e)
  //       })
  //     }
  //   })
  // }, [firstOpen])
 
  
  

  return (
    state ? (
        <>
          <NavigationContainer linking={linking}>
          <Stack.Navigator initialRouteName="SignIn">
            {/* <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={({ navigation, route }) => ({
                title:"MONICA tool - M1",
                // headerLeft: () => (
                //   <Button title="Query" onPress={() => navigation.navigate('Query')} />
                // ),
                headerRight: () => (
                  <>
                  <View style={{flexDirection:'row'}}>
                    {/* <View style={{paddingHorizontal:10}}>
                      <Button title="My Queries" onPress={() => navigation.navigate('Query')} />
                    </View> 
                    <View style={{paddingHorizontal:10}}>
                        <Button title="Logout" onPress={() => close()} />
                    </View>
                  </View>
                  </>
                ),
                headerStyle: {
                  backgroundColor: '#292929', 
                },
                headerTitleStyle: { margin: 20 },
                headerLeftContainerStyle: { margin: 20 },
                headerRightContainerStyle: { margin: 20 },
                headerTintColor: '#fff',
              })}
            /> */}
            <Stack.Screen
              name="Insert"
              component={InsertScreen}
              options={({ navigation, route }) => ({
                title:"Insert Screen",
                headerStyle: {
                  backgroundColor: '#292929', 
                },
                headerTitleStyle: { margin: 20 },
                headerLeftContainerStyle: { margin: 20 },
                headerRightContainerStyle: { margin: 20 },
                headerTintColor: '#fff',
              })}
            />
              <Stack.Screen
                name="SignIn"
                component={SignInScreen}
                options={{
                  title:"MONICA tool - SignIn",
                  // headerLeft: () => (
                  //   <Button title="menu" onPress={() => navigation.openDrawer()} />
                  // ),
                  headerStyle: {
                    backgroundColor: '#292929'
                  },
                  headerTintColor: '#fff',
                }}
              />
            {/* <Stack.Screen
              name="Query"
              component={QueryScreen}
              options={({ navigation, route }) => ({
                title:"My Queries",
                headerStyle: {
                  backgroundColor: '#292929', 
                },
                headerTitleStyle: { margin: 20 },
                headerLeftContainerStyle: { margin: 20 },
                headerRightContainerStyle: { margin: 20 },
                headerTintColor: '#fff',
              })}
            /> */}
          </Stack.Navigator>
          </NavigationContainer>
      </>
      ):(
      <>
        <NavigationContainer linking={linking}>
          <Stack.Navigator>
            <Stack.Screen
              name="SignIn"
              component={SignInScreen}
              options={{
                title:"MONICA tool - SignIn",
                // headerLeft: () => (
                //   <Button title="menu" onPress={() => navigation.openDrawer()} />
                // ),
                headerStyle: {
                  backgroundColor: '#292929'
                },
                headerTintColor: '#fff',
              }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </>
      )
    
  );
}


export default App
